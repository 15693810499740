import springApi, { getDomain } from '../../services/springApi'

export const getCoupons = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/coupons', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getCoupon = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/coupons/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeCoupon = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/coupons', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateCoupon = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/coupons/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteCoupon = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/coupons/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchCoupons = async (term, token, scopeId) => (
	fetch( getDomain()+`/coupons?filter[sku]=${term}&filter[employee_id]=${scopeId}&filter[valid]=1`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(coupon => ({
				label: coupon.sku,
				value: coupon.id,
			})),
		)
)