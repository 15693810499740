import springApi, { getDomain } from '../../services/springApi'

export const getProductRates = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/product_rates', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getProductRate = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/product_rates/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeProductRate = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/product_rates', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateProductRate = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/product_rates/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteProductRate = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/product_rates/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchProductRates = async (name, token, scope) => (
	fetch( getDomain()+`/product_rates?filter[product_desc]=${name}&filter[active]=1&filter[category_name]=${scope}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(product_rate => ({
				label: `${product_rate.product_desc} (${product_rate.code_ln})`,
				value: product_rate.id,
				employee_price: product_rate.employee_price,
			})),
		)
)

export const importProductRates = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/product_rates/import', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)