import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap'
import { Form } from 'react-bootstrap'
import { message } from 'antd'
import moment from 'moment'

import ReferralOrderForm from './partials/ReferralOrderForm'
// import CommingSoon from '../../components/CommingSoon'

import { storeReferral } from '../Referrals/services'
import { storeReferralOrder } from './services'
import { handleError } from '../../helpers'

export default function NewReferralOrder() {
	const { user } = useSelector(state => state.auth)
	const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm()
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	const onSubmit = values => {
		setLoading(true)
		values.expire_at = moment().add(1,'month').format('YYYY-MM-DD HH:mm:ss')
		storeReferral({ 
			employee_id: user.id,
			name: values.referral_name, 
			document: values.referral_document, 
			email: values.referral_email,
			mobile: values.referral_mobile,
		})
			.then(res => {
				storeReferralOrder({...values, referral_id: res.data.data.id})
					.then(res => {
						message.success(res.data.message)
						message.info(res.data.vtex_message)
						history.push('/referral_orders')
					})
					.catch(error => {
						handleError(error)
						setLoading(false)
					})
			})
			.catch(handleError)
	}

	// return <CommingSoon />

	return (
		<React.Fragment>
			<Row>
				<Col sm="12" md="8" lg="6">
					<Card>
						<CardHeader>
							<CardTitle>Enviar incentivo a referido</CardTitle>
						</CardHeader>
						<CardBody>
							<Form onSubmit={handleSubmit(onSubmit)}>
								<ReferralOrderForm 
									register={register}
									errors={errors}
									setValue={setValue}
									watch={watch}
								/>
								<Button color="primary" type="submit" disabled={loading}>
									Enviar Incentivo
								</Button>
							</Form>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}