import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

export default function LinkCard({ to, title, description, icon }) {
	return (
		<React.Fragment>
			<Link to={to}>
				<Card>
					<CardBody className='p-2'>
						<div style={{ display:'flex', alignItems: 'center' }}>
							<div className='mr-2 ml-2'>
								<FontAwesomeIcon 
									icon={icon}
									size="2x"
									className='text-link'
								/>
							</div>
							<div style={{ flex:4 }}>
								<p className='mb-0 text-link bold'>
									{title}
								</p>
								<p className='mb-0 text-muted small'>
									{description}
								</p>
							</div>
						</div>
					</CardBody>
				</Card>
			</Link>
		</React.Fragment>
	)
}