import React from 'react'
import { Empty, Table } from 'antd'

import Pagination from '../../../components/Pagination'
import { number, round } from '../../../helpers'

export default function ProductRatesTable({ productRates, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Producto',
			dataIndex: 'product_desc',
		},
		{
			title: 'Código LN Producto',
			dataIndex: 'code_ln',
			responsive: ['md']
		},
		{
			title: 'Precio IVA incluido',
			dataIndex: 'employee_price',
			render: t => `$${number(round(t))} COP`,
			responsive: ['sm']
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-0'><strong>Tarifa Empleado (IVA incluido): </strong>${number(round(r.employee_price))} COP</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={productRates}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!productRates}
				size="small"
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}