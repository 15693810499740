import React from 'react'
import { Badge } from 'reactstrap'
import { Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

import { deleteReferralOrder } from '../services'
import { handleError } from '../../../helpers'

export default function ReferralOrderTable({ referralOrders, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Solicitud No',
			dataIndex: 'id',
			responsive: ['sm']
		},
		{
			title: 'Referido',
			dataIndex: 'referral',
			render: t => t.name
		},
		{
			title: 'Cédula',
			dataIndex: 'referral',
			render: t => t.document,
			responsive: ['md']
		},
		{
			title: 'Incentivo',
			dataIndex: 'incentive',
			render: t => t.name,
		},
		{
			title: 'Cupon eCommerce',
			dataIndex: 'vtex_referral_code',
			render: t => t ? t : <em>No aplica</em>
		},
		{
			title: 'Fecha de envió',
			dataIndex: 'created_at',
			responsive: ['md']
		},
		{
			title: 'Fecha vencimiento',
			dataIndex: 'expire_at',
			render: t => `${t} (${moment(t).fromNow()})`,
			responsive: ['md']
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					{ r.status.key !== 'completed' && (
						<Tooltip title="Eliminar">
							<Popconfirm
								title="¿Desea eliminar esta solicitud?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(r.id)} 
								okButtonProps={{ danger: true }}
							>
								<FontAwesomeIcon
									className='text-link'
									icon={faTrash}
								/>
							</Popconfirm>
						</Tooltip>
					)}
				</React.Fragment>
			)
		}
	]
	
	const handleDelete = id => {
		deleteReferralOrder(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={referralOrders}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!referralOrders}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}