import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import EmployeeRewardsTable from './partials/EmployeeRewardsTable'

import { handleError, parsePagination } from '../../helpers'
import { getEmployeeRewards } from './services'

export default function EmployeeRewardsList() {
	const [employeeRewards, setEmployeeRewards] = useState(null)
	const [filterType, setFilterType] = useState('reward.name')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!employeeRewards && getEmployeeRewards({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'employee,reward'
		})
			.then(res => {
				setEmployeeRewards(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ employeeRewards, pagination, filterType, filterValue, filterScope ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Listado de premios redimidos"
				subtitle="Listado de solicitudes de redención de premios"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setEmployeeRewards(null)}
				options={[
					{ label: 'Buscar por incentivo', value: 'reward.name' },
				]}
				scopes={[
					{ label: 'Redenciones por confirmar', value: 'pendding' },
					{ label: 'Redenciones confirmadas', value: 'confirmed' },
					{ label: 'Redenciones rechazadas', value: 'rejected' },
					{ label: 'Redenciones anuladas', value: 'voided' },
				]}
			/>
			<Card>
				<CardBody>
					<EmployeeRewardsTable 
						employeeRewards={employeeRewards}
						reload={() => setEmployeeRewards(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}