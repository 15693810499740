import React from 'react'
import { Badge } from 'reactstrap'
import { Empty, Table } from 'antd'

export default function IncentivesTable({ incentives }) {	
	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'name',
		},
		{
			title: 'Términos y Condiciones',
			dataIndex: 'terms',
			responsive: ['md'],
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>,
		},
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-0'>
				<strong>Términos y condiciones:</strong>{" "}
				{r.terms}
			</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={incentives}
				rowKey={r => r.id}
				columns={columns}
				loading={!incentives}
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
		</React.Fragment>
	)
}