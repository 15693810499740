import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Row, Spinner } from 'reactstrap'

import { getEmployee } from './services'
import { handleError, number, round } from '../../helpers'

export default function EmployeePointsBalance() {
	const { user } = useSelector(state => state.auth)
	const [employee, setEmployee] = useState(null)

	useEffect(() => {
		!employee && getEmployee(user.id, {
			appends: 'points'
		})
			.then(res => setEmployee(res.data.data))
			.catch(handleError)
	}, [ employee, user ])

	if(!employee) return <Spinner />

	return (
		<React.Fragment>
			<Row>
				<Col sm="12" md="8" lg="6">
					<Card>
						<CardHeader>
							<CardTitle>Resumen de puntos {user.fullname}</CardTitle>
							<CardSubtitle>Acumula puntos con cada venta que realice un referido tuyo o utilizando un cupo asignado a tu usuario</CardSubtitle>
						</CardHeader>
						<CardBody>
							<p><strong>Total puntos acumulados: </strong>{number(round(employee.points.total))} puntos</p>
							<p><strong>Total puntos usados: </strong>{number(round(employee.points.used))} puntos</p>
							<hr />
							<p><strong>Total puntos disponibles: </strong>{number(round(employee.points.available))} puntos</p>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}