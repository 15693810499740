import React from 'react'
import { Badge } from 'reactstrap'
import { Empty, Table } from 'antd'

import Pagination from '../../../components/Pagination'
import { number } from '../../../helpers'

export default function OrderInvoicesTable({ orderInvoices, orderKey, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Factura',
			dataIndex: 'invoice_reference'
		},
		{
			title: 'No de Pedido',
			dataIndex: orderKey,
			render: t => t ? `Pedido #00${t.id}` : '',
			responsive: ['md']
		},
		{
			title: 'Fecha de registro',
			dataIndex: 'created_at',
			responsive: ['sm']
		},
		{
			title: 'Fecha confirmación',
			dataIndex: 'confirmed_at',
			render: t => t ? t : <em>Sin confirmar</em>,
			responsive: ['md']
		},
		{
			title: 'Valor de venta',
			dataIndex: 'total_value',
			render: t => t ? `$${number(t)} COP` : <em>Sin confirmar</em>,
			responsive: ['md']
		},
		{
			title: 'Puntos Acumulados',
			dataIndex: 'points',
			render: t => t ? `${number(t)} puntos` : <em>Sin confirmar</em>,
			responsive: ['sm']
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={orderInvoices}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!orderInvoices}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}