import React, { useState } from 'react'
import { Badge } from 'reactstrap'
import { Empty, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import Pagination from '../../../components/Pagination'
import EditCouponOrderModal from './EditCouponOrderModal'

export default function CouponOrderTable({ couponOrders, reload, pagination, setPagination }) {
	const [editCouponOrderModal, setEditCouponOrderModal] = useState(null)
	
	const columns = [
		{
			title: 'Solicitud No',
			dataIndex: 'id',
			responsive: ['sm']
		},
		{
			title: 'Cupon No',
			dataIndex: 'coupon',
			render: t => t.sku
		},
		{
			title: 'Canal',
			dataIndex: 'channel',
			render: t => t === 'stores' ? 'Tienda Spring' : 'eCommerce Spring',
			resposive: ['sm']
		},
		{
			title: 'Medio de Pago',
			dataIndex: 'payment_method_name',
			responsive: ['md']
		},
		{
			title: 'Cupon eCommerce',
			dataIndex: 'vtex_coupon_code',
			render: t => t ? t : <em>No aplica</em>
		},
		{
			title: 'Fecha de solicitud',
			dataIndex: 'created_at',
			responsive: ['md']
		},
		{
			title: 'Fecha vencimiento',
			dataIndex: 'expire_at',
			responsive: ['md']
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					{ (r.status.key === 'authorized' && r.payment_method === 'others') && (
						<Tooltip 
							title="Editar producto" 
							className='text-primary'
							onClick={() => setEditCouponOrderModal(r)}
						>
							<FontAwesomeIcon icon={faEdit} />
							{" "}Editar
						</Tooltip>
					)}
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-0'><strong>Producto:</strong> {r.product_rate.product_desc} ({r.product_rate.code_ln})</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={couponOrders}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				expandable={{ expandedRowRender }}
				loading={!couponOrders}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
			{ editCouponOrderModal && (
				<EditCouponOrderModal 
					visible
					onClose={() => setEditCouponOrderModal(null)}
					couponOrder={editCouponOrderModal}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}