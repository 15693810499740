import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import CouponOrdersList from './CouponOrdersList'
import NewCouponOrder from './NewCouponOrder'

export default function CouponOrders() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/coupon_orders" component={CouponOrdersList} />
				<Route exact path="/coupon_orders/new" component={NewCouponOrder} />

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}