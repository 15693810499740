import React from "react"
import {useSelector} from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"
import { useIdleTimer } from 'react-idle-timer'

import MainLayout from "./layouts/MainLayout"

import Authentication from './containers/Authentication/'
import Error404 from './containers/ErrorsPages/Error404'
import Coupons from "./containers/Coupons"
import CouponOrders from "./containers/CouponOrders"
import EmployeeRewards from "./containers/EmployeeRewards"
import ProductRates from "./containers/ProductRates"
import Incentives from "./containers/Incentives"
import OrderInvoices from "./containers/OrderInvoices"
import Rewards from "./containers/Rewards"
import ReferralOrders from "./containers/ReferralOrders"
import HomeDashboard from "./containers/HomeDashboard"
import Employees from "./containers/Employees"

export default function App() {	
	const token = useSelector(state => state.auth.token)

	const handleOnIdle = event => {
		if(token) window.location.href = '/#/auth/logout'
	}
  
	useIdleTimer({
		timeout: 1000 * 60 * 60,
		onIdle: handleOnIdle,
		debounce: 500
	})
	
	return (
		<React.Fragment>			
			<Switch>
				<Redirect exact path="/" to="/home" />

				<Route path="/auth" component={Authentication} />
				{!token && <Redirect to="/auth/login" />}

				<MainLayout>
					<Route path="/home" component={HomeDashboard} />
					<Route path="/coupons" component={Coupons} />
					<Route path="/coupon_orders" component={CouponOrders} />
					<Route path="/order_invoices" component={OrderInvoices} />
					<Route path="/incentives" component={Incentives} />
					<Route path="/referral_orders" component={ReferralOrders} />
					<Route path="/rewards" component={Rewards} />
					<Route path="/employee_rewards" component={EmployeeRewards} />
					<Route path="/product_rates" component={ProductRates} />
					<Route path="/employees" component={Employees} />
					
					<Route path="/404" component={Error404} />
				</MainLayout>
			</Switch>
		</React.Fragment>
	);	
}