import React from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { faCalculator, faCashRegister, faDollarSign, faGift, faInbox, faPaperPlane, faTags, faTrophy } from '@fortawesome/free-solid-svg-icons'

import LinkCard from './partials/LinkCard'

import { capitalize } from '../../helpers'

export default function HomeDashboard() {
	const { user } = useSelector(state => state.auth)

	return (
		<React.Fragment>
			<div className='text-center'>
				<h5 className='mb-0'>Bienvenido, {capitalize(user.name)}</h5>
				<h6 className='text-muted'>Portal de ventas a empleados Spring</h6>
				<hr />
			</div>
			<Row>
				<Col md="4">
					<h6 className='text-muted text-center mb-3'>Utilizar cupos de compra</h6>
					<LinkCard 
						to="/coupons"
						icon={faTags}
						title="Consulta tus cupos disponibles"
						description="Listado de cupos para compra de colchones y complementarios que tienes asignados"
					/>
					<LinkCard 
						to="/coupon_orders/new"
						icon={faInbox}
						title="Solicitar uso de cupo"
						description="Registra los datos necesarios para utlizar un cupo y haz seguimiento a tus solicitudes"
					/>
					<LinkCard 
						to="/product_rates"
						icon={faCalculator}
						title="Consulta las tarifas de empleados"
						description="Consulta las tarifas especiales para empleados Spring para los productos habilitados"
					/>
				</Col>
				<Col md="4">
					<h6 className='text-muted text-center mb-3'>Referir amigos y familires</h6>
					<LinkCard 
						to="/incentives"
						icon={faDollarSign}
						title="Listado de incentivos para referidos"
						description="Consulta los incentivos comerciales que puede ofrecer a sus referidos"
					/>
					<LinkCard 
						to="/referral_orders/new"
						icon={faPaperPlane}
						title="Enviar incentivo a referido"
						description="Ingresa los datos de tu referido para enviarle el incentivo comercial"
					/>
					<LinkCard 
						to="/order_invoices/referrals"
						icon={faCashRegister}
						title="Seguimiento a compras de referidos"
						description="Da seguimiento a las facturas registradas de tus referidos en tiendas Spring"
					/>
				</Col>
				<Col md="4">
					<h6 className='text-muted text-center mb-3'>Redimir puntos ganados</h6>
					<LinkCard 
						to="/employees/points"
						icon={faTrophy}
						title="Consultar puntos acumulados"
						description="Consulta tu saldo actual de puntos, acumulaciones y rendenciones"
					/>
					<LinkCard 
						to="/rewards"
						icon={faGift}
						title="Catálogo de premios con puntos"
						description="Consulta los premios que puedes redimir con los puntos acumulados"
					/>
					<LinkCard 
						to="/employee_rewards"
						icon={faInbox}
						title="Solicitudes de redención de premios"
						description="Consulta el estado de tus solicitudes de redención o solicita una nueva"
					/>
				</Col>
			</Row>
		</React.Fragment>
	)
}