import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import LogoSpringLight from '../assets/images/logo_spring_light.png'
import MainMenu from './partials/MainMenu'

export default function MainLayout({ children }) {
	const [openMenu, setOpenMenu] = useState(false)
	const user = useSelector(state => state.auth.user)

	return (
		<Container>
			<TopBar className="no-print">
				<img src={LogoSpringLight} alt="Logo Spring" width="100px" className="mr-4" />
				<FontAwesomeIcon 
					icon={ openMenu ? faTimes : faBars}
					onClick={()=>setOpenMenu(!openMenu)}
					className="nav-toggle-icon"
				/>
				<div className="float-right mt-2 hide-sm">
					<p>{user.fullname}</p>
				</div>
			</TopBar>
			{ openMenu && (
				<MainMenu closeMenu={()=>setOpenMenu(false)} />
			)}
			<MainContent className='main_layout_container'>
				{children}
			</MainContent>
			<Footer>
				<p>Desarrollado por{" "}
					<a href="//smart4.com.co" target="_blank" rel="noreferrer">
						Smart4 Solutions
					</a>
					{" | "}
					Versión {process.env.REACT_APP_VERSION}
				</p>
			</Footer>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	background-color: #f7f8f9;	
`

const TopBar = styled.div`
	width: 100%;
	padding: 5px 30px;
	background-color: #e42537;
	color: #fff;
	position: fixed;
	top: 0px;
	z-index: 10;
`

const MainContent = styled.div`
	padding: 30px;
	width: 100%;
	min-height: 90vh;
	position:relative;
	top: 52px;
`

const Footer = styled.div`
	padding: 30px 20px 20px;
	width: 100%;
	text-align: center;
`