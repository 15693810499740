import React, { useState, useEffect } from 'react'
import { Form, Spinner, Row, Col } from 'react-bootstrap'
import { Empty } from 'antd'
import styled from 'styled-components'

import { getIncentives } from '../../Incentives/services'
import { handleError } from '../../../helpers'

export default function ReferralOrderForm({ register, errors, setValue, watch }) {
	const [incentives, setIncentives] = useState(null)
	const [selectedIncentive, setSelectedIncentive] = useState([])

	useEffect(() => {
		!incentives && getIncentives({
			'filter[active]': 1,
			'filter[is_public]': 1,
		})
			.then(res => setIncentives(res.data.data))
			.catch(error => handleError(error))
	}, [ incentives ])
	
	useEffect(() => {
		if(selectedIncentive.value) 
			setValue('incentive_id', selectedIncentive.value)
	}, [ selectedIncentive, setValue ])

	if(!incentives) return <Spinner animation='border' size='sm' />

	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre del Referido <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('referral_name', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.referral_name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Cédula del Referido <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					type="number"
					{...register('referral_document', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.referral_document && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col} md="6">
					<Form.Label>Email del Referido <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('referral_email', { required:true })}
						placeholder="Escriba aquí..."
					/>
					{ errors.referral_email && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
				<Form.Group as={Col} md="6">
					<Form.Label>Celular del Referido <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('referral_mobile', { required:true })}
						placeholder="Escriba aquí..."
						type="number"
					/>
					{ errors.referral_mobile && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Incentivo <span className='text-danger'>*</span></Form.Label>
				{ incentives.length > 0 ? (
					<Row>
						{ incentives.map(incentive => (
							<Col key={incentive.id} md="6">
								<IncentiveOptionDiv 
									active={watch('incentive_id') === incentive.id} 
									onClick={() => {
										setValue('incentive_id', incentive.id)
										setSelectedIncentive(incentive)
									}}
								>
									<p><strong>{incentive.name}</strong></p>
								</IncentiveOptionDiv>
							</Col>
						)) }
					</Row>
				) : (
					<Empty description="No tiene cupones pendientes de solicitar" />
				)}
				<Form.Control {...register('incentive_id', { required:true })} style={{ display:'none' }} />
				{ selectedIncentive.terms && <Form.Text className='text-muted'>
					<strong>Términos y condiciones: </strong>
					{selectedIncentive.terms}
				</Form.Text> }
				{ errors.incentive_id && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Canal donde desea redimir <span className='text-danger'>*</span></Form.Label>
				<Form.Control
					as="select"
					{...register("channel", { required:true })}
				>
					<option value="">:: Seleccione una opción ::</option>
					<option value="stores">Tiendas Spring</option>
					<option value="ecommerce" disabled>eCommerce Spring (Proximamente)</option>
				</Form.Control>
				{ errors.channel && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}

const IncentiveOptionDiv = styled.div`
	padding: 7px 15px;
	text-align: center;
	border: ${props => !props.active ? '1px solid #e8e8e8' : '1px solid #e42537'};
	color: ${props => !props.active ? '#333' : '#e42537'};
	:hover {
		border: 1px solid #e42537;
		color: #e42537;
		cursor: pointer;
		font-weight: bold;
	}
	p {
		margin-bottom: 3px;
	}
`