import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
// import CommingSoon from '../../components/CommingSoon'

import RewardsTable from './partials/RewardsTable'

import { handleError } from '../../helpers'
import { getRewards } from './services'

export default function RewardsList() {
	const [rewards, setRewards] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(() => {
		!rewards && getRewards({
			[`filter[${filterType}]`]: filterValue,
			'filter[active]': 1,
		})
			.then(res => setRewards(res.data.data))
			.catch(error => handleError(error))
	}, [ rewards, filterType, filterValue ])

	// return <CommingSoon />

	return (
		<React.Fragment>
			<ListTopBar 
				title="Catálogo de premios para redimir"
				subtitle="Acumula puntos refiriendo y comprando en Spring"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setRewards(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
				]}
			/>
			<Card>
				<CardBody>
					<RewardsTable rewards={rewards} />
				</CardBody>
			</Card>
		</React.Fragment>
	)
}