import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import CouponOrdersTable from './partials/CouponOrdersTable'

import { handleError, parsePagination } from '../../helpers'
import { getCouponOrders } from './services'

export default function CouponOrdersList() {
	const { user } = useSelector(state => state.auth)
	const [couponOrders, setCouponOrders] = useState(null)
	const [filterType, setFilterType] = useState('coupon.sku')
	const [filterValue, setFilterValue] = useState('')
	const [filterScope, setFilterScope] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const history = useHistory()

	useEffect(() => {
		!couponOrders && getCouponOrders({
			...pagination,
			'filter[coupon.employee_id]': user.id,
			[`filter[${filterType}]`]: filterValue,
			[filterScope ? `filter[${filterScope}]` : 'noscope']: 1,
			include: 'coupon.couponType,coupon.employee,productRate'
		})
			.then(res => {
				setCouponOrders(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ couponOrders, pagination, filterType, filterValue, filterScope, user ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Listado de solicitudes realizadas"
				subtitle="Solicitudes de utilización de cupos recibidas"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				filterScope={filterScope}
				setFilterScope={setFilterScope}
				reload={() => setCouponOrders(null)}
				options={[
					{ label: 'Buscar por código de cupo', value: 'coupon.sku' },
				]}
				scopes={[
					{ label: 'Solicitudes pendientes', value: 'pendding' },
					{ label: 'Solicitudes pendientes de autorización', value: 'authorization_pendding' },
					{ label: 'Solicitudes autorizadas', value: 'authorized' },
					{ label: 'Solicitudes vencidas', value: 'expired' },
					{ label: 'Solicitudes completadas', value: 'completed' },
					{ label: 'Solicitudes anuladas', value: 'voided' },
				]}
				ctaText="Nueva Solicitud"
				handleCtaClick={() => history.push('/coupon_orders/new')}
			/>
			<Card>
				<CardBody>
					<CouponOrdersTable 
						couponOrders={couponOrders}
						reload={() => setCouponOrders(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}