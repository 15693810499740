import React from 'react'
import { Badge } from 'reactstrap'
import { Divider, Empty, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import Pagination from '../../../components/Pagination'

import { deleteEmployeeReward } from '../services'
import { handleError } from '../../../helpers'

export default function EmployeeRewardsTable({ employeeRewards, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Redención No',
			dataIndex: 'id',
			responsive: ['sm']
		},
		{
			title: 'Premio redimido',
			dataIndex: 'reward',
			render: t => t.name
		},
		{
			title: 'Fecha de solicitud',
			dataIndex: 'created_at',
			responsive: ['sm']
		},
		{
			title: 'Fecha de confirmación',
			dataIndex: 'confirmed_at',
			render: t => t ? t : <em>Sin confirmar</em>,
			responsive: ['md']
		},
		{
			title: 'Fecha de rechazo',
			dataIndex: 'rejected_at',
			render: t => t ? t : <em>Sin rechazo</em>,
			responsive: ['md']
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		},
		{
			title: 'Acciones',
			render: (t,r) => (
				<React.Fragment>
					{ r.status.key === 'pendding' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Tooltip title="Eliminar">
								<Popconfirm
									title="¿Desea eliminar esta solicitud?"
									okText="Eliminar"
									cancelText="Cancelar"
									onConfirm={() => handleDelete(r.id)}
									okButtonProps={{ danger: true }}
								>
									<FontAwesomeIcon
										className='text-link'
										icon={faTrash}
									/>
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]
	
	const handleDelete = id => {
		deleteEmployeeReward(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(handleError)
	}
	
	return (
		<React.Fragment>
			<Table 
				dataSource={employeeRewards}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!employeeRewards}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}