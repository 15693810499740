import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'
import { Form } from 'react-bootstrap'

import ServerSideSelect from '../../../components/ServerSideSelect'

import { searchProductRates } from '../../ProductRates/services'
import { updateCouponOrder } from '../services'
import { handleError, number } from '../../../helpers'

export default function EditCouponOrderModal({ visible, onClose, couponOrder, reload }) {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm()
	const [selectedProductRate, setSelectedProductRate] = useState([])
	const [employeeRate, setEmployeeRate] = useState(null)
	const [loading, setLoading] = useState(null)

	useEffect(() => {
		if(selectedProductRate.value)
			setValue('product_rate_id', selectedProductRate.value)
	}, [selectedProductRate, setValue])

	const onSubmit = values => {
		setLoading(true)

		updateCouponOrder(couponOrder.id, values)
			.then(res => {
				message.success(res.data.message)
				reload()
				onClose()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<Modal isOpen={visible} toggle={onClose}>
			<ModalHeader toggle={onClose}>Actualizar producto</ModalHeader>
			<ModalBody>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group>
						<Form.Label>Nombre del producto a comprar <span className='text-primary'>*</span></Form.Label>
						<ServerSideSelect
							value={selectedProductRate}
							placeholder="Buscar por nombre"
							fetchOptions={searchProductRates}
							scopeId={couponOrder.coupon.sku.includes('COLCH') ? 'COLCH' : 'COMP,LEN,ALM,BASE,CAM,MUE,ACC'}
							onChange={(value, r) => {
								setSelectedProductRate(value)
								setEmployeeRate(r.employee_price)
							}}
							style={{ width: '100%', marginBottom: '7px' }}
						/>
						<Form.Control {...register('product_rate_id', { required:true })} style={{ display:'none' }} />
						{ errors.product_rate_id && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						{ employeeRate && <Form.Text className='text-primary bold'>Tarifa empleado sugerida: $ {number(employeeRate)} COP</Form.Text> }
					</Form.Group>
					<Button color="primary" type="submit" disabled={loading}>
						Actualizar Producto
					</Button>
				</Form>
			</ModalBody>
		</Modal>
	)
}