import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Result } from 'antd'

export default function SuccessCouponOrderModal({ visible, couponOrder, reset }) {
	const history = useHistory()

	const onClose = () => {
		history.push('/coupon_orders')
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible} toggle={onClose}>
				<ModalHeader toggle={onClose}>Solicitud de uso de cupo radicada</ModalHeader>
				<ModalBody>
					{ couponOrder.authorized_at ? (
						<Result 
							status="success"
							title="¡Felicidades tu cupo ha sido autorizado!"
							subTitle="Tu cupo fue autorizado y esta listo para utilizarse."
							className='p-2'
							extra={[
								<Button color='primary' onClick={onClose}>Ver mis solicitudes</Button>,
								<Button color='primary' outline onClick={reset}>Solicitar otro cupo</Button>,
								<Button color='primary' outline
									onClick={couponOrder.channel === 'ecommerce' ? () => window.open('https://www.colchonesspring.com.co') : () => window.open('https://www.colchonesspring.com.co/tiendas-abiertas')}
								>
									{ couponOrder.channel === 'ecommerce' ? 'Entrar sitio web' : 'Buscar tienda cercana' }
								</Button>,
							]}
						>
							<div className='desc'>
								<h6>Preguntas Frecuentes</h6>
								<ul className='pl-3'>
									<li>
										<p className='mb-0'><strong>¿Donde redimo?</strong>{"  "}
											{ couponOrder.channel === 'ecommerce' ? 'En la página web de Colchones Spring ingresando su cupón único al finalizar la compra.' : 'En cualquier tienda Spring presentando sú cédula al asesor.' }
										</p>
									</li>
									<li>
										<p className='mb-0'><strong>¿Cómo redimo?</strong>{"  "}
											{ couponOrder.channel === 'ecommerce' ? '1. Ingrese a colchonesspring.com.co. 2. Seleccione los productos que desea comprar. 3. Ingrese al carrito de compras. 4. En el chackout ingrese el cupon único que recibio en su correo para aplicar el descuento.' : '1. Dirijase a cualquier tienda Spring. 2. Al llegar verifique que la tienda cuente con el inventario del producto. 3. Indique al asesor su cédula para que proceda a validar el cupo autorizado. 4. Finalice la compra.' }
										</p>
									</li>
									<li>
										<p className='mb-0'><strong>¿Hasta cuando puedo redimir?</strong>{"  "}
											Su cupo se vence el: <em>{couponOrder.expire_at}</em>
										</p>
									</li>
								</ul>
							</div>
						</Result>
					) : (
						<Result 
							status="warning"
							title="Solicitud pendiente de autorización."
							subTitle="Te informaremos cuando tu solicitud sea aprobada para su uso."
							className='p-2'
							extra={[
								<Button color='primary' onClick={onClose}>Ver mis solicitudes</Button>,
								<Button color='primary' outline onClick={reset}>Solicitar otro cupo</Button>,
							]}
						>
							<div className='desc'>
								<h6>Preguntas Frecuentes</h6>
								<ul className='pl-3'>
									<li>
										<p className='mb-0'><strong>¿Donde redimo?</strong>{"  "}
											{ couponOrder.channel === 'ecommerce' ? 'Una vez aprobado. En la página web de Colchones Spring ingresando su cupón único al finalizar la compra.' : 'Una vez aprobado. En cualquier tienda Spring presentando sú cédula al asesor.' }
										</p>
									</li>
									<li>
										<p className='mb-0'><strong>¿Hasta cuando puedo redimir?</strong>{"  "}
											Una vez aprobado puede redimir su cupo hasta: <em>{couponOrder.expire_at}</em>
										</p>
									</li>
								</ul>
							</div>
						</Result>
					)}
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}