import React from 'react'
import { Badge } from 'reactstrap'
import { Empty, Table } from 'antd'
import moment from 'moment'
import 'moment/locale/es'

import Pagination from '../../../components/Pagination'

export default function CouponsTable({ coupons, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'Cupo No',
			dataIndex: 'sku',
		},
		{
			title: 'Tipo de cupo',
			dataIndex: 'coupon_type',
			render: t => t.name,
			responsive: ['sm']
		},
		{
			title: 'Fecha de asignación',
			dataIndex: 'created_at',
			responsive: ['md']
		},
		{
			title: 'Fecha vencimiento',
			dataIndex: 'end_at',
			render: t => `${t} (${moment(t).fromNow()})`,
			responsive: ['sm']
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={coupons}
				rowKey={r => r.id}
				pagination={false}
				columns={columns}
				loading={!coupons}
				size="small"
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
			<Pagination 
				pagination={pagination}
				setPagination={setPagination}
				reload={reload}
				className="mb-0"
			/>
		</React.Fragment>
	)
}