import React from 'react'
import { Badge } from 'reactstrap'
import { Empty, Table } from 'antd'

import { number } from '../../../helpers'

export default function RewardsTable({ rewards }) {
	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'name',
		},
		{
			title: 'Términos y condiciones',
			dataIndex: 'terms',
			responsive: ['md']
		},
		{
			title: 'Pts requeridos',
			dataIndex: 'points_required',
			render: t => `${number(t)} puntos`
		},
		{
			title: 'Estado',
			dataIndex: 'status',
			render: t => <Badge color={t.color}>{t.label}</Badge>,
			responsive: ['sm']
		}
	]

	const expandedRowRender = r => (
		<React.Fragment>
			<p className='mb-0'>
				<strong>Términos y condiciones:</strong>{" "}
				{r.terms}
			</p>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				dataSource={rewards}
				rowKey={r => r.id}
				columns={columns}
				loading={!rewards}
				expandable={{ expandedRowRender }}
				locale={{ emptyText: (
					<Empty
						description="No se encontraron registros" 
						imageStyle={{ height: '80px' }}
					/> 
				)}}
			/>
		</React.Fragment>
	)
}