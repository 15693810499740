import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import ProductRatesTable from './partials/ProductRatesTable'

import { getProductRates } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ProductRatesList() {
	const [productRates, setProductRates] = useState(null)
	const [filterType, setFilterType] = useState('code_ln')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!productRates && getProductRates({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			'filter[active]': 1,
		})
			.then(res => {
				setProductRates(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ productRates, pagination, filterType, filterValue ])

	return (
		<React.Fragment>
			<ListTopBar 
				title="Listado de tarifas especiales para empleados"
				subtitle="Listado de todas las tarifas creadas para el simulador"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setProductRates(null)}
				options={[
					{ label: 'Buscar por código LN', value: 'code_ln' },
					{ label: 'Buscar por nombre de proucto', value: 'product_desc' },
					{ label: 'Buscar por linea de producto', value: 'product_line' },
				]}
			/>
			<Card>
				<CardBody>
					<ProductRatesTable 
						productRates={productRates}
						reload={() => setProductRates(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}