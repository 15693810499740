import React, { useState, useEffect } from 'react'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'

import IncentivesTable from './partials/IncentivesTable'
// import CommingSoon from '../../components/CommingSoon'

import { handleError } from '../../helpers'
import { getIncentives } from './services'

export default function IncentivesList() {
	const [incentives, setIncentives] = useState(null)
	const [filterType, setFilterType] = useState('name')
	const [filterValue, setFilterValue] = useState('')

	useEffect(() => {
		!incentives && getIncentives({
			[`filter[${filterType}]`]: filterValue,
			'filter[active]': 1,
			'filter[is_public]': 1,
		})
			.then(res => setIncentives(res.data.data))
			.catch(error => handleError(error))
	}, [ incentives, filterType, filterValue ])

	// return <CommingSoon />

	return (
		<React.Fragment>
			<ListTopBar 
				title="Catálogo de incentivos a referidos"
				subtitle="Incentivos habilitados para ofrecer a referidos"
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				reload={() => setIncentives(null)}
				options={[
					{ label: 'Buscar por nombre', value: 'name' },
				]}
			/>
			<Card>
				<CardBody>
					<IncentivesTable incentives={incentives} />
				</CardBody>
			</Card>
		</React.Fragment>
	)
}