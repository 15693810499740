import springApi, { getDomain } from '../../services/springApi'

export const getReferrals = params => (
	new Promise((resolve, reject) => {
		springApi()
			.get('/referrals', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getReferral = (id, params) => (
	new Promise((resolve, reject) => {
		springApi()
			.get(`/referrals/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeReferral = data => (
	new Promise((resolve, reject) => {
		springApi()
			.post('/referrals', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateReferral = (id, data) => (
	new Promise((resolve, reject) => {
		springApi()
			.put(`/referrals/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteReferral = id => (
	new Promise((resolve, reject) => {
		springApi()
			.delete(`/referrals/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchReferrals = async (name, token, scopeId) => (
	fetch( getDomain()+`/referrals?filter[name]=${name}&filter[employee_id]=${scopeId}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(referral => ({
				label: `${referral.name} (${referral.document})`,				
				value: referral.id,
			})),
		)
)